import xjNodeApi from './api-xj-node'
import xjItemApi from './api-xj-item'
import xjRecordApi from './api-xj-record'
import xjRecordNodeApi from './api-xj-record-node'
import xjUserRealInfoApi from './api-xj-user-real-info'
import xjStatisticsApi from './api-xj-statistics'


const xj = {
  xjNode: xjNodeApi(),
  xjItem: xjItemApi(),
  xjRecord: xjRecordApi(),
  xjRecordNode: xjRecordNodeApi(),
  xjUserRealInfo: xjUserRealInfoApi(),
  xjStatistics: xjStatisticsApi()
}
export default xj