import { RouteView } from '../components/layouts'

const reportForm = {
  path: '/reportForms',
  component: RouteView,
  redirect: '/reportForms/safetyProduction',
  name: 'safetyProduction',
  meta: { title: '报表', keepAlive: false },
  children: [
    {
      path: '/reportForms/safetyProduction',
      name: 'safetyProduction',
      component: () => import('../views/reportForms/safetyProduction/List'),
      meta: { title: '安全生产日报表' }
    },
  ]

}
export default reportForm