/**
 * 公共轮训方法
 */

import store from '../store'

import UUID from '../utils/UUID'

const millisec = 10000

setInterval(() => {
  const uuid = new UUID().id
  store.commit('SET_INTERVAL_MESSAGE', uuid)
}, millisec)