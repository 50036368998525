<template>
  <div class="user-wrapper">
    <!--<span class="action">
      <a @click="handleHelp" style="color: #001529">
        <a-icon type="question-circle-o"></a-icon>
      </a>
    </span>-->
    <a-dropdown>
      <span class="action ant-dropdown-link user-dropdown-menu">
        <span
        >{{ defaultOrgJob.organizationFullName }}
          <template v-if="defaultOrgJob.jobId"> <a-divider type="vertical"/>{{ defaultOrgJob.jobName }} </template
          >&nbsp;&nbsp;<a-icon type="swap-o"></a-icon
          ></span>
      </span>
      <a-menu slot="overlay">
        <a-menu-item v-for="item in currentUser.organizationJobs" :key="item.id">
          <a target="_blank" @click="handleChangeOrgJob(item)"
          >{{ item.organizationFullName }}
            <template v-if="item.jobId">
              <a-divider type="vertical"/>
              {{ item.jobName }}
            </template>
            &nbsp;&nbsp;<a-icon v-if="item.id === defaultOrgJob.id" type="check" style="color: #1e88e5;"/>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <header-notice class="action"/>
    <a-dropdown>
      <span class="action ant-dropdown-link user-dropdown-menu">
        <a-avatar class="avatar" style="background-color:#1e88e5" v-if="!currentUser.headImg" size="small">{{
          currentUser.name.substring(0, 1)
        }}</a-avatar>
        <a-avatar style="background-color:#1e88e5" class="avatar" :alt="currentUser.name.substring(0, 1)"
                  v-if="currentUser.headImg" :src="bucketUrl+currentUser.headImg"
                  size="small"></a-avatar>
        <span>{{ currentUser.name }}</span>
      </span>
      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <a-menu-item key="0">
          <router-link :to="{ name: 'userSetting' }">
            <a-icon type="user"></a-icon>
            <span>个人中心</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="1">
          <router-link :to="{ name: 'userSecurity' }">
            <a-icon type="setting"></a-icon>
            <span>安全设置</span>
          </router-link>
        </a-menu-item>
        <!--<a-menu-item key="2" disabled>
          <a-icon type="setting"></a-icon>
          <span>测试</span>
        </a-menu-item>-->
        <a-menu-divider></a-menu-divider>
        <a-menu-item key="3">
          <a @click="handleLogout">
            <a-icon type="logout"></a-icon>
            <span>退出登录</span>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'
  import HeaderNotice from './HeaderNotice'
  import * as types from '../../store/mutation-types'
  import config from '../../config/defaultSettings'
  import { mapState } from 'vuex'

  export default {
    name: 'UserMenu',
    components: {
      HeaderNotice,
    },
    computed: {
      ...mapState({
        currentUser: state => state.user.currentUser,
        bucketUrl: state => {
          return state.user.bucketUrl
        }
      }),
      defaultOrgJob () {
        const organizationJobs = this.currentUser.organizationJobs
        let temp = {}
        organizationJobs.forEach((x) => {
          if (x.ifDefault) {
            temp = x
          }
        })
        return temp
      },
    },
    methods: {
      loginOut () {
        this.$store.commit(types.CLEAR_LOGIN_INFO)
        this.$router.push('/cert/login')
      },
      handleLogout () {
        const that = this
        this.$confirm({
          title: '提示',
          content: '真的要退出登录吗 ?',
          onOk () {
            that.loginOut()
          },
          onCancel () {},
        })
      },
      handleHelp () {
        this.$router.push({
          path: '/help',
        })
      },
      handleChangeOrgJob (item) {
        if (item.id === this.defaultOrgJob.id) return
        this.$store.commit('SET_GLOBAL_LOADING', true)
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.updateDefault,
          params: {
            id: item.id,
          },
          noTips: true,
          success: () => {
            this.$store.commit('SET_GLOBAL_LOADING', false)
            this.$store.commit('SET_BASIC_DATA_LOADING', false)
            this.$nextTick(() => {
              if (this.$route.path === config.homePage.default) {
                window.location.reload()
              }
              this.$router.replace(config.homePage.default)
            })
          },
          error: () => {
            this.$store.commit('SET_GLOBAL_LOADING', false)
          },
        })
      },
    },
  }
</script>
