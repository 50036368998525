import { RouteView } from '../components/layouts'

const exampleRouterConfig = {
  path: '/base',
  component: RouteView,
  redirect: '/system/notice/list',
  name: 'base',
  meta: { title: '系统通知', keepAlive: false },
  children: [
    {
      path: '/notice/list',
      name: 'NoticeList',
      component: () => import('../views/plat/notice/List'),
      meta: { title: '通知管理', keepAlive: true }
    },
    {
      path: '/notice/state/list',
      name: 'NoticeStateList',
      component: () => import('../views/plat/notice/StateList'),
      meta: { title: '我的通知', keepAlive: true }
    },
    {
      path: '/supervise/list',
      name: 'SuperviseList',
      component: () => import('../views/plat/supervise/List'),
      meta: { title: '督办管理', keepAlive: true }
    },
    {
      path: '/supervise/state/list',
      name: 'SuperviseStateList',
      component: () => import('../views/plat/supervise/StateList'),
      meta: { title: '我的督办', keepAlive: true }
    },
    {
      path: '/ocr/list',
      name: 'OcrList',
      component: () => import('../views/plat/ocr/List'),
      meta: { title: 'OCR文档管理', keepAlive: true }
    },
  ]

}
export default exampleRouterConfig