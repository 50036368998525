import * as equipment from '../../../core/authority/equipment'
import * as baseInfo from '../../../core/authority/baseInfo'
import * as system from '../../../core/authority/system'
import * as equipment2 from '../../../core/authority/equipment2'
import * as car from '../../../core/authority/car'
import * as damageReport from '../../../core/authority/damageReport'
import * as duty from '../../../core/authority/duty'

const mixin = {
  data () {
    return {
      auth_equipment: equipment,
      auth_baseInfo: baseInfo,
      auth_system: system,
      auth_equipment2: equipment2,
      auth_car: car,
      auth_damageReport: damageReport,
      auth_duty: duty
    }
  }
}

export default mixin