import serverUrl from './service.info'

const apiVersion = '/api/v1/invoke'

const invokeApi = {

  view: {
    path: serverUrl + apiVersion + '/view',
    method: 'GET',
  },
}
export default invokeApi
