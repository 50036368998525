import serverUrl from './service.info'

const apiVersion = '/api/v1'

const appUpgrade = {
  view: {
    path: serverUrl + apiVersion + '/app/upgrade/record/{id}/view',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  create: {
    path: serverUrl + apiVersion + '/app/upgrade/record/create',
    method: 'POST',
  },
  search: {
    path: serverUrl + apiVersion + '/app/upgrade/record/search',
    method: 'GET',
  },
  delete: {
    path: serverUrl + apiVersion + '/app/upgrade/record/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String',
    },
  },
  deleteMore: {
    path: serverUrl + apiVersion + '/app/upgrade/record/delMore',
    method: 'DELETE',
    params: {
      idList: 'SearchPoint.vue',
    },
  },
  update: {
    path: serverUrl + apiVersion + '/app/upgrade/record/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
}

export default appUpgrade
