import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/facility'
const facilityApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    mapSearch: {
      path: serverUrl + apiVersion + base + '/map/search',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    searchDevice: {
      path: serverUrl + apiVersion + base + '/searchDevice',
      method: 'GET'
    },
    selectDevice: {
      path: serverUrl + apiVersion + base + '/selectDevice',
      method: 'GET'
    },
    saveMappings: {
      path: serverUrl + apiVersion + base + '/{id}/saveMappings',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    removeDevice: {
      path: serverUrl + apiVersion + base + '/{deviceId}/removeDevice',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    selectFacility: {
      path: serverUrl + apiVersion + base + '/selectFacility',
      method: 'GET'
    },
    aggregationInfo: {
      path: serverUrl + apiVersion + base + '/aggregationInfo/{facilityId}',
      method: 'GET',
      params: {
        facilityId: 'Long'
      }
    },
    facilityTypeTree: {
      path: serverUrl + apiVersion + base + '/facility_type_tree',
      method: 'GET'
    }
  }
}
export default facilityApi