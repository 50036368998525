import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1/system/job'

export default {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET'
  },
  tree: {
    path: serverUrl + apiVersion + '/tree',
    method: 'GET'
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
    params: {
      id: 'String'
    }
  },
  select: {
    path: serverUrl + apiVersion + '/select',
    method: 'GET'
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST'
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
    params: {
      id: 'String'
    }
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String'
    }
  },
  delMore: {
    path: serverUrl + apiVersion + '/delMore',
    method: 'DELETE',
    params: {
      idList: 'SearchPoint.vue'
    }
  },
  selectOne: {
    path: serverUrl + apiVersion + '/select/one',
    method: 'GET'
  },
  selectByPid: {
    path: serverUrl + apiVersion + '/selectByPid',
    method: 'GET'
  },
  moveNode: {
    path: serverUrl + apiVersion + '/move',
    method: 'GET'
  },
  selectByOrg: {
    path: serverUrl + apiVersion + '/select_by_org',
    method: 'GET'
  }
}