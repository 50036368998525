import serverUrl from './service.info'

const apiVersion = '/api/v1'

const organization = {
  create: {
    path: serverUrl + apiVersion + '/organization/create',
    method: 'POST',
  },
  view: {
    path: serverUrl + apiVersion + '/organization/{id}/view',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  init: {
    path: serverUrl + apiVersion + '/user/init',
    method: 'GET',
  },
  select: {
    path: serverUrl + apiVersion + '/organization/select',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  selectUnits: {
    path: serverUrl + apiVersion + '/organization/selectUnits',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  selectByPid: {
    path: serverUrl + apiVersion + '/organization/selectByPid',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  tree: {
    path: serverUrl + apiVersion + '/organization/tree',
    method: 'GET',
  },
  update: {
    path: serverUrl + apiVersion + '/organization/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  treeData: {
    path: serverUrl + apiVersion + '/organization/tree/data',
    method: 'GET',
    params: {
      rId: 'Long',
    },
  },
  delete: {
    path: serverUrl + apiVersion + '/organization/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String',
    },
  },
  sorter: {
    path: serverUrl + apiVersion + '/organization/sorter',
    method: 'PUT',
  },
  selectSelfChildren: {
    path: serverUrl + apiVersion + '/organization/select/self/children',
    method: 'GET',
  },
  moveNode: {
    path: serverUrl + apiVersion + '/organization/move',
    method: 'GET',
  },
  antTree: {
    path: serverUrl + apiVersion + '/organization/ant_tree',
    method: 'GET',
  },
  orgUserTree: {
    path: serverUrl + apiVersion + '/organization/org_user_tree',
    method: 'GET',
  },
}

export default organization
