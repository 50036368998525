import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '../config/defaultSettings'
// import Viser from 'viser-vue'
import 'ant-design-vue/dist/antd.less'
import '../plugins/zTree/css/zTreeStyle/zTreeStyle.css'
import 'font-awesome/css/font-awesome.min.css'
import '../styles/index.less'
import './permission'

// 全局指令
import malihuScrollbar from '../directives/malihu-scrollbar'
// 权限认证
import authDirective from '../directives/auth'
// 滚动监听
import vueScroll from 'vue-scroll'
// 图片延迟加载
import VueLazyload from 'vue-lazyload'

import VueFroala from 'vue-froala-wysiwyg'

// ext library 剪贴板
import VueClipboard from 'vue-clipboard2'

import auth from '../plugins/auth'

import axios from '../plugins/axios/axios'
import request from '../plugins/axios/request'

//公共轮询方法
import '../utils/interval'

Vue.directive('malihu-scrollbar', malihuScrollbar)
//权限指令判断
Vue.directive('auth', authDirective)

VueClipboard.config.autoSetContainer = true
// 图标
// Vue.use(Viser)
// 本地数据操作插件 localStorage sessionStorage等
Vue.use(VueStorage, config.storageOptions)
Vue.use(VueClipboard)
Vue.use(axios)
Vue.use(request)
//权限代码判断
Vue.use(auth)
//jquery div 滚动条
Vue.use(vueScroll)
//图片延迟加载
Vue.use(VueLazyload, config.imageLazyOptions)
//加载富文本
Vue.use(VueFroala)
