import { RouteView } from '../components/layouts'

const dataVRouter = {
  path: '/datav',
  component: RouteView,
  redirect: '/datav/xingqingscreen',
  name: 'datav',
  meta: { title: '大屏', keepAlive: false },
  children: [
    // {
    //   path: '/datav/xingqingscreen',
    //   name: 'xingqingscreen',
    //   component: () => import('../views/datav/xingqingscreen/Index'),
    //  /* component: () => import('../views/datav/zhongningscreen/Index'),*/
    //   meta: { title: '兴庆大屏', keepAlive: false }
    // },
    {
      path: '/datav/zncsgzscreen',
      name: 'zncsgzscreen',
       component: () => import('../views/csgz/datev/zncsgzscreen/Index'),
      meta: { title: '中宁大屏', keepAlive: false }
    },
    {
      path: '/datav/hlcsgzscreen',
      name: 'hlcsgzscreen',
      component: () => import('../views/csgz/datev/hlcsgzscreen/Index'),
      meta: { title: '贺兰大屏', keepAlive: false }
    },
    {
      path: '/datav/ningxiascreen',
      name: 'ningxiascreen',
      component: () => import('../views/datav/ningxiascreen/Index'),
      meta: { title: '宁夏大屏', keepAlive: false }
    },
    {
      path: '/datav/xingqing',
      name: 'xingqingscreen',
      component: () => import('../views/csgz/datev/xqcsgzscreen/Index'),
      meta: { title: '兴庆大屏', keepAlive: false }
    },
    {
      path: '/datav/csgzscreen',
      name: 'xingqingscreen',
      component: () => import('../views/csgz/datev/csgzscreen/Index'),
      meta: { title: '厕所改造大屏', keepAlive: false }
    }
  ]
}

export default dataVRouter