import { RouteView } from '../components/layouts'

const csgzReportRecordRouter = {
  path: '/reportrecord',
  component: RouteView,
  redirect: '/reportrecord/list',
  name: 'reportrecord',
  meta: { title: '污水拉运', keepAlive: false },
  children: [
    {
      path: '/reportrecord/list',
      name: 'list',
      component: () => import('../views/csgz/reportrecord/report/List'),
      meta: { title: '任务登记', keepAlive: false }
    },
    {
      path: '/loop/statistics',
      name: 'statistics',
      component: () => import('../views/csgz/loopstatistics/Statistics'),
      meta: { title: '清运统计', keepAlive: false }
    },
    {
      path: '/loop/list',
      name: 'loop',
      component: () => import('../views/csgz/reportrecord/looprecord/List'),
      meta: { title: '清运管理', keepAlive: false }
    },
    {
      path: '/reportrecord/StatisticsList',
      name: 'report',
      component: () => import('../views/csgz/reportrecord/report/StatisticsList'),
      meta: { title: '上报统计', keepAlive: false }
    },
    {
      path: '/reportrecord/feedbackList',
      name: 'report',
      component: () => import('../views/csgz/reportrecord/report/FeedbackList'),
      meta: { title: '任务回访', keepAlive: false }
    },
    {
      path: '/reportrecord/confirmList',
      name: 'report',
      component: () => import('../views/csgz/reportrecord/report/ConfirmList'),
      meta: { title: '任务确认', keepAlive: false }
    },
    {
      path: '/loop/forecast',
      name: 'loopForecast',
      component: () => import('../views/csgz/forecast/List'),
      meta: { title: '清运预测', keepAlive: false }
    },
  ]
}

export default csgzReportRecordRouter