import { RouteView } from '../../components/layouts'

const monitoring = [
  {
    path: '/equipment2/warning',
    component: RouteView,
    redirect: '/equipment2/warning',
    name: 'monitoring',
    meta: { title: '设备预警', keepAlive: false },
    children: [
      {
        path: '/equipment2/warning',
        name: 'warningHome',
        component: () => import('../../views/equipment2/warning/List'),
        meta: { title: '设备预警信息列表', keepAlive: false },
      },
    ],
  },
]

export default monitoring
