import { RouteView } from '../components/layouts'

const exceptionRouterConfig = {
  path: '/exception',
  name: 'exception',
  component: RouteView,
  redirect: '/exception/403',
  meta: { title: '异常页', icon: 'warning', permission: ['exception'] },
  children: [
    {
      path: '403',
      name: 'Exception403',
      component: () => import('../views/other/exception/403'),
      meta: { title: '403' }
    },
    {
      path: '404',
      name: 'Exception404',
      component: () => import('../views/other/exception/404'),
      meta: { title: '404' }
    },
    {
      path: '500',
      name: 'Exception500',
      component: () => import('../views/other/exception/500'),
      meta: { title: '500' }
    }
  ]
}

export default exceptionRouterConfig