import { RouteView } from '../../components/layouts'

const depot = [
  {
    path: '/equipment2/depot',
    component: RouteView,
    redirect: '/equipment2/depot',
    name: 'depotManage',
    meta: { title: '仓库管理', keepAlive: false },
    children: [
      {
        path: '/equipment2/depot',
        name: 'depot',
        component: () => import('../../views/equipment2/depot/List'),
        meta: { title: '仓库管理列表', keepAlive: false }
      }
    ]
  }
]

export default depot