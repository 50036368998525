import serverUrl from '@/api/service.info'
const apiVersion = '/api/v1'
const base = '/my/ticket'
const myTicketApi = () => {
  return {
    statistic: {
      path: serverUrl + apiVersion + base + '/statistic',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
  }
}
export default myTicketApi