<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]" style="position: relative">
    <div class="container">
      <div class="top">
        <div class="header">
          <!--<a href="/">
            &lt;!&ndash;            <img src="~@/assets/logo.svg" class="logo" alt="logo">&ndash;&gt;
            <span class="title">污水生产运营管理平台</span>
          </a>-->
        </div>
        <div class="desc">
          <!--Ant Design 是西湖区最具影响力的 Web 设计规范-->
        </div>
      </div>
      <route-view></route-view>
      <div class="footer">
        <img src="/images/login_key.png" width="900"/>
        <br>
        <br>
        <br>
        <div class="links">
          <a style="color: white" href="http://sthjt.nx.gov.cn/" target="_blank">宁夏回族自治区生态环境厅</a>
          <a style="color: white" href="https://www.mem.gov.cn/" target="_blank">宁夏环保集团有限公司</a>
          <a style="color: white" href="http://sthjj.yinchuan.gov.cn/" target="_blank">银川市生态环境局贺兰分局</a>
          <a style="color: white" href="http://sthjj.yinchuan.gov.cn/" target="_blank">银川市生态环境局兴庆分局</a>
        </div>
        <!--<div class="links">
          <a href="_self">帮助</a>
          <a href="_self">隐私</a>
          <a href="_self">条款</a>
        </div>-->
        <div class="copyright" style="color: white">
          <a href="https://beian.miit.gov.cn" target="_blank" style="color: white; margin-right: 20px">宁ICP备2022001610号-1</a>
          Copyright &copy; {{new Date().getFullYear()}} {{config.systemInfo.companyName}}
        </div>
      </div>
    </div>
    <div class="absolute-btn">
      <a-popover placement="topRight">
        <template slot="content">
          <div style="text-align: center">
            <img style="height: 100px;width: 100px;" src="/gh_fa07324078eb_258.jpg" alt="小程序二维码">
          </div>
          <div style="color: #808695;text-align: center;margin-top: 5px">微信小程序</div>
          <!--<div style="display: flex;flex-direction: column;">-->
          <!--  <span style="color: #808695;text-align: center">微信小程序</span>-->
          <!--</div>-->
        </template>
        <!--<template slot="title">-->
        <!--  <span style="text-align: center">扫描下方体验小程序</span>-->
        <!--</template>-->
        <div class="circular-btn">
          <a-icon type="qrcode" style="font-size: 22px"/>
        </div>
      </a-popover>
      <a-popover placement="topRight">
        <template slot="content">
          <p>电&nbsp;&nbsp;话：15009666891</p>
          <p>技术支持：周庆朋</p>
        </template>
        <template slot="title">
          <span>联系我们</span>
        </template>
        <div class="circular-btn" style="margin-top: 15px">
          <a-icon type="question" style="font-size: 22px"/>
        </div>
      </a-popover>
    </div>
  </div>
</template>

<script>
  import RouteView from './RouteView'
  import { mixinDevice } from '../../utils/mixin.js'
  import config from '../../config/defaultSettings'

  export default {
    name: 'UserLayout',
    components: { RouteView },
    mixins: [mixinDevice],
    data () {
      return { config: config }
    },
    mounted () {
      document.body.classList.add('userLayout')
    },
    beforeDestroy () {
      document.body.classList.remove('userLayout')
    }
  }
</script>

<style scoped>
  .absolute-btn {
    position: absolute;
    bottom: 25px;
    right: 20px;
  }

  .circular-btn {
    width: 40px;
    height: 40px;
    transition: all;
    cursor: pointer;
    transition-duration: .3s;
    transition-timing-function: ease;
    border-radius: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
</style>