import { RouteView } from '../components/layouts'

const ticketRouter = {
  path: '/ticket',
  component: RouteView,
  redirect: '/ticket/my',
  name: 'ticket',
  meta: { title: '工单调度', keepAlive: false },
  children: [
    {
      path: '/ticket/my',
      name: 'my',
      component: () => import('../views/ticket/info/List'),
      meta: { title: '我的工单', keepAlive: false }
    },
    {
      path: '/ticket/all',
      name: 'all',
      component: () => import('../views/ticket/all/List'),
      meta: { title: '工单管理', keepAlive: false }
    },
    /*{
      path: '/ticket/damagereport',
      name: 'phone',
      component: () => import('../views/ticket/damagereport/List'),
      meta: { title: '电话处理记录', keepAlive: false }
    },*/
  ]
}

export default ticketRouter