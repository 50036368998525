import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1/role'

export default {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
    params: {
      number: 'String',
      size: 'String',
      direction: 'String',
      order: 'String',
      q: 'String'
    }
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST'
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
    params: {
      id: 'String'
    }
  },
  dataUpdate: {
    path: serverUrl + apiVersion + '/dataAuth/update',
    method: 'POST'
  },
  selectTag: {
    path: serverUrl + apiVersion + '/select/{tag}',
    method: 'GET',
    params: {
      tag: 'String'
    }
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String'
    }
  },
  delMore: {
    path: serverUrl + apiVersion + '/delMore',
    method: 'DELETE',
    params: {
      idList: 'SearchPoint.vue'
    }
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
    params: {
      id: 'String'
    }
  },
  select: {
    path: serverUrl + apiVersion + '/select',
    method: 'GET'
  },
  selectByType: {
    path: serverUrl + apiVersion + '/getRolesSelectByType',
    method: 'GET'
  },
  currentUserRoleType: {
    path: serverUrl + apiVersion + '/type/list',
    method: 'GET'
  },
  resourceSave: {
    path: serverUrl + apiVersion + '/{id}/resource/save',
    method: 'POST'
  },
  checkAndId: {
    path: serverUrl + apiVersion + '/check/{id}/{role}',
    method: 'GET'
  },
  check: {
    path: serverUrl + apiVersion + '/check/{role}',
    method: 'GET'
  }
}