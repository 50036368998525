import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/statistics'
const carStatisticsApi = () => {
  return {
    statistics: {
      path: serverUrl + apiVersion + base + '/record',
      method: 'POST'
    },
  }
}
export default carStatisticsApi