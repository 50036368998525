/* eslint-disable */
import Vue from 'vue'

import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

const initFnc = function (el, binding, vnode) {
  if (binding.value === 'x') {
    Vue.nextTick(() => {
      $(el).mCustomScrollbar({
        mouseWheel: {
          enable: true,
          axis: 'x',
          preventDefault: true,
          invert: true
        },
        autoHideScrollbar: false,
        horizontalScroll: true,
        theme: "light",
        advanced: {
          autoScrollOnFocus: false,
          autoExpandHorizontalScroll: 3,
          updateOnImageLoad: false
        }
      })
    })
  } else if (binding.value === 'yx') {
    Vue.nextTick(() => {
      $(el).mCustomScrollbar({
        mouseWheel: {
          enable: true,
          preventDefault: true
        },
        axis: 'yx',
        advanced: {
          autoExpandHorizontalScroll: true,
          updateOnImageLoad: false
        }
      })
    })
  } else {
    Vue.nextTick(() => {
      $(el).mCustomScrollbar({
        mouseWheel: {
          enable: true,
          axis: 'y',
        },
        autoHideScrollbar: false,
        theme: "light",
        advanced: {
          updateOnContentResize: true,
          updateOnImageLoad: false
        },
        callbacks: {
          whileScrolling: function () {
            if (vnode.context.malihuOnScrollHandle) {
              const offsetHeight = this.mcs.content[0].offsetHeight;
              const offsetTop = this.mcs.content[0].offsetTop;
              const height = $(this).height();
              vnode.context.malihuOnScrollHandle(offsetHeight - (height - offsetTop), offsetTop, this);
            }
          }
        }
      })
    })
  }
}

const mailhuScrollbar = {
  bind(el, binding, vnode) {
    initFnc(el, binding, vnode)
  },
  unbind: function (el) {
    $(el).mCustomScrollbar('update')
  }
}

export default mailhuScrollbar
