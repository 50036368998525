import serverUrl from './service.info'

const apiVersion = '/api/v1'

const user = {
  expand: {
    search: {
      path: serverUrl + apiVersion + '/expand/user/search',
      method: 'GET',
    },
    search_all: {
      path: serverUrl + apiVersion + '/expand/user/search_all',
      method: 'GET',
    },
    search_no_auth: {
      path: serverUrl + apiVersion + '/expand/user/search_no_auth',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/expand/user/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    select: {
      path: serverUrl + apiVersion + '/expand/user/select',
      method: 'GET',
    },
    selectAll: {
      path: serverUrl + apiVersion + '/expand/user/select/all',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + '/expand/user/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/expand/user/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    createNew: {
      path: serverUrl + apiVersion + '/expand/user/create/new',
      method: 'POST',
    },
    updateNew: {
      path: serverUrl + apiVersion + '/expand/user/{id}/update/new',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    updateBase: {
      path: serverUrl + apiVersion + '/expand/user/{id}/update/base',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/expand/user/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/expand/user/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  menu: {
    list: {
      path: serverUrl + apiVersion + '/menu/list',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/menu/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    create: {
      path: serverUrl + apiVersion + '/menu/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/menu/override/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/menu/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/menu/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
  },
  view: {
    path: serverUrl + apiVersion + '/user/{id}/view',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  detail: {
    path: serverUrl + apiVersion + '/user/{id}/detail',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  userInfo: {
    path: serverUrl + apiVersion + '/system/user/userInfo',
    method: 'PUT',
  },
  password: {
    path: serverUrl + apiVersion + '/system/user/password',
    method: 'PUT',
  },
  checkPassword: {
    path: serverUrl + apiVersion + '/system/user/checkPassword',
    method: 'POST',
  },
  create: {
    path: serverUrl + apiVersion + '/user/create',
    method: 'POST',
  },
  import: {
    path: serverUrl + apiVersion + '/user/import',
    method: 'POST',
  },
  search: {
    path: serverUrl + apiVersion + '/user/search',
    method: 'GET',
  },
  delete: {
    path: serverUrl + apiVersion + '/user/{id}/del',
    method: 'DELETE',
    params: {
      id: 'String',
    },
  },
  deleteMore: {
    path: serverUrl + apiVersion + '/user/delMore',
    method: 'DELETE',
    params: {
      idList: 'SearchPoint.vue',
    },
  },
  update: {
    path: serverUrl + apiVersion + '/user/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  ownUpdate: {
    path: serverUrl + apiVersion + '/user/{id}/own_update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  reset: {
    path: serverUrl + apiVersion + '/user/reset/{id}',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  select: {
    path: serverUrl + apiVersion + '/user/select',
    method: 'GET',
  },
  selectOrdinary: {
    path: serverUrl + apiVersion + '/user/select/ordinary',
    method: 'GET',
  },
  updateHeadimg: {
    path: serverUrl + apiVersion + '/user/update/headimg',
    method: 'PUT',
    params: {
      // headImg: "String"
    },
  },
  localUpdateHeadImg: {
    path: serverUrl + apiVersion + '/user/local/update/headimg',
    method: 'PUT',
  },
  updateName: {
    path: serverUrl + apiVersion + '/user/update/name',
    method: 'PUT',
    params: {
      name: 'String',
    },
  },
  updateNickname: {
    path: serverUrl + apiVersion + '/user/update/nickname',
    method: 'PUT',
  },
  updateMobile: {
    path: serverUrl + apiVersion + '/user/update/mobile',
    method: 'PUT',
  },
  choose: {
    path: serverUrl + apiVersion + '/user/choose',
    method: 'GET',
  },
  loginRecords: {
    path: serverUrl + apiVersion + '/user/login/records',
    method: 'GET',
  },
  moveAndSorter: {
    path: serverUrl + apiVersion + '/user/{uId}/move/{oId}/to/{mId}',
    method: 'PUT',
  },
  sorter: {
    path: serverUrl + apiVersion + '/user/sorter',
    method: 'PUT',
  },
  company: {
    path: serverUrl + apiVersion + '/user/company',
    method: 'GET',
  },
  changeCompany: {
    path: serverUrl + apiVersion + '/user/company/change/{id}',
    method: 'POST',
  },
  userOrgJob: {
    view: {
      path: serverUrl + apiVersion + '/system/user_org_job/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    orgUser: {
      path: serverUrl + apiVersion + '/system/user_org_job/list/current/org/user',
      method: 'GET',
    },
    updateDefault: {
      path: serverUrl + apiVersion + '/system/user_org_job/update/default/{id}',
      method: 'POST',
      params: {
        id: 'Long',
      },
    },
    search: {
      path: serverUrl + apiVersion + '/system/user_org_job/search',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    select: {
      path: serverUrl + apiVersion + '/system/user_org_job/select',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + '/system/user_org_job/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/system/user_org_job/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/system/user_org_job/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
  },
  job: {
    search: {
      path: serverUrl + apiVersion + '/system/job/search',
      method: 'GET',
    },
    tree: {
      path: serverUrl + apiVersion + '/system/job/tree',
      method: 'GET',
    },
    view: {
      path: serverUrl + apiVersion + '/system/job/{id}/view',
      method: 'GET',
      params: {
        id: 'String',
      },
    },
    select: {
      path: serverUrl + apiVersion + '/system/job/select',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + '/system/job/create',
      method: 'POST',
    },
    update: {
      path: serverUrl + apiVersion + '/system/job/{id}/update',
      method: 'PUT',
      params: {
        id: 'String',
      },
    },
    delete: {
      path: serverUrl + apiVersion + '/system/job/{id}/del',
      method: 'DELETE',
      params: {
        id: 'String',
      },
    },
    deleteMore: {
      path: serverUrl + apiVersion + '/system/job/delMore',
      method: 'DELETE',
      params: {
        idList: 'SearchPoint.vue',
      },
    },
    selectOne: {
      path: serverUrl + apiVersion + '/system/job/select/one',
      method: 'GET',
    },
    selectByPid: {
      path: serverUrl + apiVersion + '/system/job/selectByPid',
      method: 'GET',
    },
    moveNode: {
      path: serverUrl + apiVersion + '/system/job/move',
      method: 'GET',
    },
  },
  select_user: {
    path: serverUrl + apiVersion + '/user/select_user',
    method: 'GET',
  },
}

export default user
