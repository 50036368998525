/**
 * 网络请求工具类
 *
 * $this 当前 this
 *
 * subConfig
 *
 * @param noLoadingBar 顶部加载
 * @param params 替换 {key:value} ex: url 中 {key} -> {value}
 * @param data ex {key:value}  GET 请求 自动放在 url?key=value 其他 @RequestBody 获取
 * @param url 请求参数
 * @param loading 请求状态名称 默认 ifSub
 * @param success 请求成功回调 系统返回 code = 0
 * @param error 请求失败回调 系统返回 code = -1
 * @param noTips 设置为true 不弹出默认请求成功提醒
 * @param noErrorTips 设置为 treu 不弹出默认请求失败提醒
 * @param successTipsContent 请求自定义提醒
 * @param errorTipsContent 请求失败自定义提醒
 *
 * Created by yangyang on 2019/03/16.
 */
import Vue from 'vue'
// 加载进度条
import NProgress from 'nprogress' // progress bar
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const instance = ($this, subConfig) => {
  sub_($this, subConfig)
}

function sub_(self, subConfig) {
  loadingStatus(self, subConfig, true)
  if (!subConfig.noLoadingBar) {
    NProgress.start()
  }
  let url = subConfig.url.path
  //设置地址参数 /{id}
  if (subConfig.params) {
    for (let key in subConfig.params) {
      if (subConfig.params.hasOwnProperty(key)) {
        url = url.replace('{' + key + '}', encodeURIComponent(subConfig.params[key]))
      }
    }
  }
  let config = {
    url: url,
    method: subConfig.url.method,
    timeout: 10 * 60 * 1000,
  }
  if (subConfig.url.method === 'GET') {
    config['params'] = subConfig.data
  } else {
    config['data'] = subConfig.data
  }
  if (subConfig.export) {
    config['responseType'] = subConfig.responseType
    Vue.http.request(config).then(data => {
      loadingStatus(self, subConfig, false)
      if (data) {
        subConfig.success(data)
      }
      if (!subConfig.noLoadingBar) {
        NProgress.done()
      }
    }).catch(err => {
      if (!subConfig.noLoadingBar) {
        NProgress.done()
      }
      error(subConfig, err)
      self.$message.error(err.message)
    })
  } else {
    Vue.http.request(config).then(data => {
      loadingStatus(self, subConfig, false)
      if (data.code === 0) {
        if (subConfig.success) {
          subConfig.success(data)
        }
        if (!subConfig.noTips) {
          let successMessage = data.message
          if (subConfig.successTipsContent) {
            successMessage = subConfig.successTipsContent
          }
          self.$message.success(successMessage)
        }
      } else {
        if (!subConfig.noErrorTips && data.messageCode !== 'no.login') { //未登录这里将取消提示，直接弹出登录框
          let errorMessage = data.message
          if (subConfig.errorTipsContent) {
            errorMessage = subConfig.errorTipsContent
          }
          self.$message.error(errorMessage)
        }
        if (data.messageCode === 'no.login') {
          //弹出登陆框
          self.$store.commit('SET_LOGIN_MODAL_STATUS', true)
        }
        error(subConfig, data.messageCode, data)
      }
      if (!subConfig.noLoadingBar) {
        NProgress.done()
      }
    }).catch(err => {
      loadingStatus(self, subConfig, false)
      if (!subConfig.noLoadingBar) {
        NProgress.done()
      }
      error(subConfig, err)
      self.$message.error(err.message)
    })
  }
}

function error(subConfig, err, data) {
  if (subConfig.error) {
    subConfig.error(err, data)
  }
}

function loadingStatus(self, subConfig, status) {
  if (subConfig.loading) {
    if (subConfig.loading !== 'no') {
      self[subConfig.loading] = status
    }
  } else {
    self.ifSub = status
  }
}

const plugin = {}

plugin.install = (Vue) => {
  if (plugin.installed) {
    return
  }
  Vue.prototype.$http = instance
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
