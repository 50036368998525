import serverUrl from '@/api/service.info'
const apiVersion = '/api/v1'
const base = '/device/metrics/config'
const equipmentConfigApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    selectConfig: {
      path: serverUrl + apiVersion + base + '/{categoryId}/selectConfig',
      method: 'GET',
      params: {
        categoryId: 'Long'
      }
    },
    selectGateWay: {
      path: serverUrl + apiVersion + base + '/selectGateWay',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    }
  }
}
export default equipmentConfigApi