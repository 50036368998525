import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/gps'

const carGisApi = () => {
  return {
    timeGis: {
      path: serverUrl + apiVersion + base + '/time_gis',
      method: 'GET'
    },
    timeGisTrail: {
      path: serverUrl + apiVersion + base + '/time_gis_trail',
      method: 'GET'
    },
  }
}
export default carGisApi