import serverUrl from '../service.info'

const apiVersion = '/api/v1/amap_layer/info'

const layerInfo = {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
  },
  searchByMapInfoId: {
    path: serverUrl + apiVersion + '/search_by_map_info_id/{mapInfoId}',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
  selectTree: {
    path: serverUrl + apiVersion + '/select_tree/{mapInfoId}',
    method: 'GET',
  },
}

export default layerInfo
