<template>
  <a-form
    id="formLogin"
    class="user-layout-login"
    ref="formLogin"
    :form="form">
    <a-alert v-if="type === 'reLogin'" type="error" message="登录凭证已失效，请重新登录。" class="m-b-24" banner/>
    <a-form-item>
      <a-input
        size="large"
        type="text"
        placeholder="帐户名或邮箱地址"
        v-decorator="[
                        'username',
                        {rules: [{ required: true, message: '请输入帐户名或邮箱地址' }], validateTrigger: 'change'}
                      ]">
        <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"></a-icon>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        size="large"
        type="password"
        autocomplete="false"
        placeholder="密码"
        v-decorator="[
                        'password',
                        {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
                      ]">
        <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"></a-icon>
      </a-input>
    </a-form-item>
    <a-form-item class="remember-me">
      <a-checkbox v-decorator="['rememberMe',{initialValue:true,valuePropName:'checked'}]">自动登陆</a-checkbox>
      <!--      <router-link-->
      <!--        :to="{ path:'/cert/login' }"-->
      <!--        class="forge-password"-->
      <!--        style="float: right;"-->
      <!--      >忘记密码-->
      <!--      </router-link>-->
    </a-form-item>

    <a-form-item class="m-b-none">
      <a-button
        block
        size="large"
        type="primary"
        htmlType="submit"
        class="login-button"
        :loading="loginStatus"
        @click.stop.prevent="handleSubmit"
      >登录
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>

  import SERVICE_URLS from '../../api/service.url'
  import config from '../../config/defaultSettings'

  export default {
    name: 'LoginForm',
    props: {
      type: {
        type: String,
        default: 'login' // reLogin 重新登录
      }
    },
    data () {
      return {
        loginStatus: false,
        smsSendBtn: false,
        time: 60,
        form: this.$form.createForm(this)
      }
    },
    methods: {
      // login submit
      handleSubmit () {
        const {
          form: { validateFields }
        } = this
        this.loginStatus = true
        // const validateFieldsKey = customActiveKey === 'tab1' ? ['username', 'password', 'rememberMe'] : ['mobile', 'captcha', 'rememberMe']
        const validateFieldsKey = ['username', 'password', 'rememberMe']
        validateFields(validateFieldsKey, { force: true }, (err, values) => {
          if (!err) {
            const dto = {
              username: '',
              password: '',
              rememberMe: true,
              loginType: 'username'
            }
            // if (customActiveKey === 'tab1') {
            //   dto['username'] = values['username']
            //   dto['password'] = values['password']
            // } else {
            //   dto['username'] = values['mobile']
            //   dto['password'] = values['captcha']
            //   dto['loginType'] = 'mobile'
            // }
            dto['username'] = values['username']
            dto['password'] = values['password']
            dto['rememberMe'] = values['rememberMe'] || false
            // console.log('login form', dto)
            this.$http(this, {
              url: SERVICE_URLS.auth.login,
              data: dto,
              loading: 'loginStatus',
              noTips: 'true',
              success: (data) => {
                // console.log(data)
                // 后期可根据不同用户跳转不同页面
                if (this.type === 'reLogin') { // 弹出了登录页面
                  self.location.reload()
                } else {
                  this.$router.push(config.homePage.default)
                }
              }
            })
          } else {
            setTimeout(() => {
              this.loginStatus = false
            }, 600)
          }
        })
      }
    }
  }
</script>