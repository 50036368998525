<template>
  <div class="global-content-footer">
    <div class="links">
      <a href="http://sthjt.nx.gov.cn/" target="_blank">宁夏回族自治区生态环境厅</a>
      <a href="https://www.mem.gov.cn/" target="_blank">宁夏环保集团有限公司</a>
      <a href="http://sthjj.yinchuan.gov.cn/" target="_blank">银川市生态环境局贺兰分局</a>
      <a href="http://sthjj.yinchuan.gov.cn/" target="_blank">银川市生态环境局兴庆分局</a>
    </div>
    <div class="links">
      <a>帮助</a>
      <a>隐私</a>
      <a>条款</a>
    </div>
    <div class="copyright">
      Copyright &copy; {{new Date().getFullYear()}} {{config.systemInfo.companyName}}
    </div>
  </div>
</template>

<script>
  import config from '../../config/defaultSettings'

  export default {
    name: 'LayoutFooter',
    data () {
      return { config: config }
    }
  }
</script>
