import serverUrl from '@/api/service.info'
const apiVersion = '/api/v1'
const base = '/xunjian/node'
const nodeApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    copy: {
      path: serverUrl + apiVersion + base + '/{nodeId}/copy',
      method: 'POST',
      params: {
        nodeId: 'Long'
      }
    },
    getOrgTree: {
      path: serverUrl + apiVersion + '/device/info/orgTree',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    getAreaTree: {
      path: serverUrl + apiVersion + '/device/info/areaTree',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
  }
}
export default nodeApi