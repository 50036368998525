// 框架基础API
import auth from './earl.auth'
import resource from './earl.resource'
import organization from './earl.organization'
import article from './earl.article'
import system from './earl.system'
import document from './earl.document'
import dictionary from './earl.dictionary'
import role from './earl.role'
import user from './earl.user'
import earlBase from './earl-base/earl-base'
import extendInfo from './earl.extendInfo'
import appUpgrade from './earl.appUpgrade'
/*平台基础模块*/
import platBase from './plat.base'
import equipment2 from '@/api/equipment2/api-equipment2'
import overlay from './map/map.overlay'
import mapInfo from './map/map.info'
import layer from './map/map.layer'
import xj from './xj/api-xj'
import hkInfo from '@/api/hkcamera/hkcamera.manage'
import relation from '@/api/map/map.overlay.relation'
import ticket from './ticket/api-ticket'
import duty from './duty/api-duty'
import dutyNote from './dutyNote/api-dutyNote'
import reportForms from './reportForms/api-reportForms'
import invokeApi from './invoke'

/*农村厕所改造模块*/
import csgz from './csgz/api-csgz'


const SERVICE_URLS = {
  earlBase,
  auth,
  organization,
  system,
  document,
  resource,
  dictionary,
  role,
  user,
  appUpgrade,
  platBase,
  article,
  equipment2,
  overlay,
  mapInfo,
  layer,
  xj,
  hkInfo,
  relation,
  extendInfo,
  ticket,
  duty,
  dutyNote,
  reportForms,
  csgz,
  invokeApi
}

export default SERVICE_URLS
