import serverUrl from '@/api/service.info'
const apiVersion = '/api/v1'
const base = '/device/metrics/real'
const metricsRealInfoApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + base + '/createV2',
      method: 'POST',
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long',
      },
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long',
      },
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/updateV2',
      method: 'PUT',
      params: {
        id: 'Long',
      },
    },
    selectGateWay: {
      path: serverUrl + apiVersion + base + '/selectGateWay',
      method: 'get',
    },
    findList: {
      path: serverUrl + apiVersion + base + '/find_list',
      method: 'GET',
    },
    metricsHistoryList: {
      path: serverUrl + apiVersion + base + '/metrics_history_list',
      method: 'GET',
    },
    metricsHistoryPage: {
      path: serverUrl + apiVersion + base + '/metrics_history_page',
      method: 'GET',
    },
    update_sorter: {
      path: serverUrl + apiVersion + base + '/update_sorter',
      method: 'POST',
    },
  }
}
export default metricsRealInfoApi
