import equipmentType from './api-equipment-type'
import equipmentInfo from './api-equipment-info'
import equipmentConfig from './api-equipment-config'
import gateway from './api-gateway'
import depot from './api-depot'
import monitoring from './api-equipment-monitoring'
import facility from './api-facility'
import monitorPointApi from './api-monitor-point'
import metricsRealInfoApi from './api-metrics-real-info'
import warning from './api-equipment-warning'
import technologyInfo from './api-technology-info'
import technologyPointInfo from './api-technology-point-info'
import metricsType from './api-metrics-type'
import monitoringPoint from './api-monitoring-point'
import monitoringRealInfo from './api-monitoring-real-info'
import monitoringPointControl from './api-monitoring-point-control'

const equipment2 = {
  equipmentTypeApi: equipmentType(),
  equipmentInfoApi: equipmentInfo(),
  equipmentConfigApi: equipmentConfig(),
  gatewayApi: gateway(),
  depotApi: depot(),
  monitoringApi: monitoring(),
  facilityApi: facility(),
  monitorPointApi: monitorPointApi(),
  metricsRealInfoApi: metricsRealInfoApi(),
  warningApi: warning(),
  technologyInfo: technologyInfo(),
  technologyPointInfo: technologyPointInfo(),
  metricsType: metricsType(),
  monitoringPoint: monitoringPoint(),
  monitoringRealInfo: monitoringRealInfo(),
  monitoringPointControl: monitoringPointControl()
}
export default equipment2
