import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/monitoring/point'
const monitoringPointApi = () => {
  return {
    tree: {
      path: serverUrl + apiVersion + base + '/tree/{id}',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/detail/{id}',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    search_node_list: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    delete: {
      path: serverUrl + apiVersion + base + '/delete/{id}',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update_detail: {
      path: serverUrl + apiVersion + base + '/update',
      method: 'POST'
    },
    update: {
      path: serverUrl + apiVersion + base + '/update_root',
      method: 'POST'
    },
    detail_root: {
      path: serverUrl + apiVersion + base + '/detail_root/{id}',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    search_tree: {
      path: serverUrl + apiVersion + base + '/search_tree_with_not_data_auth',
      method: 'GET'
    },
    search_tree_with_data_auth: {
      path: serverUrl + apiVersion + base + '/search_tree_with_data_auth',
      method: 'GET'
    },
    search_tree_with_map_data_auth: {
      path: serverUrl + apiVersion + base + '/search_tree_with_map_data_auth',
      method: 'GET'
    },
    getTags: {
      path: serverUrl + apiVersion + base + '/getTags',
      method: 'GET'
    },
    comp_map_tree: {
      path: serverUrl + apiVersion + base + '/comp_map_tree/{mapId}',
      method: 'GET',
      params: {
        mapId: 'LONG'
      }
    },
    tree_with_data_auth: {
      path: serverUrl + apiVersion + base + '/tree_with_data_auth',
      method: 'GET'
    },
    // 无权限树
    build_tree: {
      path: serverUrl + apiVersion + base + '/build_tree',
      method: 'GET'
    },
    // 综合地图
    build_tree_with_map_data_auth: {
      path: serverUrl + apiVersion + base + '/build_tree_with_map_data_auth',
      method: 'GET'
    },
    // 综合地图树加载子节点
    unfold_tree_with_map_data_auth_and_plot_node: {
      path: serverUrl + apiVersion + base + '/unfold_tree_with_map_data_auth_and_plot_node',
      method: 'GET'
    },
    // 按权限构建树
    build_tree_with_data_auth: {
      path: serverUrl + apiVersion + base + '/build_tree_with_data_auth',
      method: 'GET'
    },
    // 统计数量
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    // 获取监测点实时监测的标签
    find_tags_by_is_monitor: {
      path: serverUrl + apiVersion + base + '/find_tags_by_is_monitor',
      method: 'GET'
    },
    // 获取监测点核心指标分页列表
    search_point_metrics_page: {
      path: serverUrl + apiVersion + base + '/search_point_metrics_page',
      method: 'GET'
    },
    statisticsByTag: {
      path: serverUrl + apiVersion + base + '/statistics_by_tag',
      method: 'GET'
    },
    spot_tree: {
      path: serverUrl + apiVersion + '/regulatory/spot/tree',
      method: 'POST'
    },
    view_by_no: {
      path: serverUrl + apiVersion + '/regulatory/spot/view_by_no',
      method: 'POST'
    },
    metrics_history_page: {
      path: serverUrl + apiVersion + '/regulatory/spot/metrics_history_page',
      method: 'GET'
    },
    metrics_history_page_by_no: {
      path: serverUrl + apiVersion + '/regulatory/spot/metrics_history_page_by_no',
      method: 'GET'
    }
  }
}
export default monitoringPointApi