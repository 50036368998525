import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/dispatch/app'
const dispatchAppApi = () => {
  return {
    dispatchCurrentStatistics: {
      path: serverUrl + apiVersion + base + '/dispatch_current_statistics',
      method: 'GET'
    },
    dispatchHistoryStatistics: {
      path: serverUrl + apiVersion + base + '/dispatch_history_statistics',
      method: 'GET'
    },
    dispatchHistoryChart: {
      path: serverUrl + apiVersion + base + '/dispatch_history_chart',
      method: 'GET'
    },
    dispatchHistoryStatisticsByArea: {
      path: serverUrl + apiVersion + base + '/dispatch_history_statistics_by_area_no',
      method: 'POST'
    },
    dispatchHistoryChartByArea: {
      path: serverUrl + apiVersion + base + '/dispatch_history_chart_by_area_no',
      method: 'POST'
    }
  }
}
export default dispatchAppApi