exports.install = function (Vue, options) {
  Vue.prototype.currentDeptId = function () {
    const deptList =  this.$store.getters.currentUser.organizationJobs
    for (let i = 0; i < deptList.length; i++) {
      if (deptList[i].ifDefault) {
        return  deptList[i]
      }
    }
  }
}

