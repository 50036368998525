import { RouteView } from '../components/layouts'

const csgzRepairRouter = {
  path: '/repair',
  component: RouteView,
  redirect: '/repair/record',
  name: 'repairRecord',
  meta: { title: '设备维修', keepAlive: false },
  children: [
    {
      path: '/repair/record',
      name: 'repairRecord',
      component: () => import('../views/csgz/repair/List'),
      meta: { title: '维修上报', keepAlive: false }
    },
    {
      path: '/ticket/info',
      name: 'ticketInfo',
      component: () => import('../views/csgz/ticket/List'),
      meta: { title: '工单管理', keepAlive: false }
    },
  ]
}

export default csgzRepairRouter