import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VuePreview from './plugins/vue-preview/src/plugins/preview'

import './core/use'

import bootstrap from './core/bootstrap'

import './utils/filter' // global filter

// 选色卡
// import Vcolorpicker from 'vcolorpicker'

/*全局混入权限导入*/
import authority from './views/common/mixins/authority'
// ↓图片插卡插件
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
/*视频播放组件样式*/
import 'video.js/dist/video-js.css'
// 获取用户切换部门后的切换部门

import currentDept from './views/common/mixins/currentDept'
import Print from 'vue-print-nb'
import { FormModel } from 'ant-design-vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(FormModel)
Vue.use(Print)
Vue.use(currentDept)
Vue.use(dataV)
Vue.use(VueClipboard)
Vue.config.productionTip = false
//图片查看
Vue.use(VuePreview)
Vue.use(router)
Vue.use(preview)
Vue.prototype.$center = new Vue();
// Vue.use(Vcolorpicker)

/*全局混入权限*/
Vue.mixin(authority)

new Vue({
  router,
  store,
  created() {
    bootstrap()
  },
  render: (h) => h(App),
}).$mount('#app')
