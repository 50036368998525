// eslint-disable-next-line
import { UserLayout, PageView } from '../components/layouts'
import exceptionRouterConfig from './excepetion.router'
import primaryRouterConfig from './primary.router'

/**
 * 所有路由配置
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/cert',
    component: UserLayout,
    redirect: '/cert/login',
    hidden: true,
    children: [
      {
        path: 'login',
        component: () => import('../views/login/Login'),
        name: 'login',
      },
    ],
  },
  primaryRouterConfig,
  exceptionRouterConfig,
]
