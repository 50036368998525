import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/car/info'

const carInfoApi = () => {
  return {
    select: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET'
    },
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    mapList: {
      path: serverUrl + apiVersion + base + '/map_list',
      method: 'GET'
    },
    synchronousDevices: {
      path: serverUrl + apiVersion + base + '/synchronous_devices',
      method: 'GET'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    viewAll: {
      path: serverUrl + apiVersion + base + '/{id}/view_all',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    count_cars: {
      path: serverUrl + apiVersion + base + '/count_cars',
      method: 'GET'
    },
    count_cars_by_offline: {
      path: serverUrl + apiVersion + base + '/count_cars_by_offline',
      method: 'GET'
    },
    count_cars_by_status: {
      path: serverUrl + apiVersion + base + '/count_cars_by_status',
      method: 'GET'
    }
  }
}
export default carInfoApi