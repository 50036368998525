import serverUrl from './service.info'

const apiVersion = '/api/v1/system/dict'

const dictionary = {
  tree: {
    path: serverUrl + apiVersion + '/tree',
    method: 'GET',
  },
  select: {
    path: serverUrl + apiVersion + '/select',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  listByPid: {
    path: serverUrl + apiVersion + '/listByPid',
    method: 'GET',
  },
  selectByPid: {
    path: serverUrl + apiVersion + '/selectByPid',
    method: 'GET',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
  moveNode: {
    path: serverUrl + apiVersion + '/move',
    method: 'GET',
  },
}
export default dictionary
