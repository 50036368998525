import { RouteView } from '../components/layouts'

const amapRouterConfig = {
  path: '/amap',
  component: RouteView,
  redirect: '/amap/list',
  name: 'amap',
  meta: { title: '地图标绘', keepAlive: false },
  children: [
    {
      path: '/amap/info',
      name: 'amapInfo',
      component: () => import('../views/map/info/List'),
      meta: { title: '地图管理', keepAlive: false },
    },
    {
      path: '/amap/overlay',
      name: 'amapOverlay',
      component: () => import('../views/map/overlay/List'),
      meta: { title: '覆盖物管理', keepAlive: false },
    },
    {
      path: '/amap/layer',
      name: 'amapLayer',
      component: () => import('../views/map/layer/List'),
      meta: { title: '图层管理', keepAlive: false },
    },
    {
      path: '/amap/cover',
      name: 'amapCover',
      component: () => import('../views/map/Cover'),
      meta: { title: '地址绘制', keepAlive: false, hideMargin: true, hideFooter: true },
    },
    {
      path: '/amap/composite/:mapId',
      name: 'compositeMap',
      component: () => import('../views/map/composite/CompositeMap'),
      props: true,
      meta: { title: '综合地图', keepAlive: false, hideMargin: true, hideFooter: true },
    },
    {
      path: '/amap/car/:mapId',
      name: 'carCompositeMap',
      component: () => import('../views/map/composite/CompositeMap'),
      props: true,
      meta: { title: '车辆地图', keepAlive: false, hideMargin: true, hideFooter: true },
    },
    {
      path: '/amap/xj/:mapId',
      name: 'xjCompositeMap',
      component: () => import('../views/map/composite/CompositeMap'),
      props: true,
      meta: { title: '人员巡检地图', keepAlive: false, hideMargin: true, hideFooter: true },
    },
    {
      path: '/amap/equipment/:mapId',
      name: 'equipmentCompositeMap',
      component: () => import('../views/map/composite/CompositeMap'),
      props: true,
      meta: { title: '设备监测地图', keepAlive: false, hideMargin: true, hideFooter: true },
    },
    // {
    //   path: '/car/history/track',
    //   name: 'HistoryTrack',
    //   component: () => import('../views/car/info/HistoryTrack'),
    //   meta: { title: '历史轨迹追踪', keepAlive: false, hideMargin: true, hideFooter: true },
    // },
  ],
}

export default amapRouterConfig
