import serverUrl from '@/api/service.info'
const apiVersion = '/api/v1/oa'
const base = '/duty'

const infoApi = () => {
  return {
    handover: {
      path: serverUrl + apiVersion + base + '/handover',
      method: 'POST'
    },
    succeed: {
      path: serverUrl + apiVersion + base + '/succeed',
      method: 'POST'
    },
    update: {
      path: serverUrl + apiVersion + base + '/update',
      method: 'POST'
    },
    delete: {
      path: serverUrl + apiVersion + base + '/del/{id}',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    view: {
      path: serverUrl + apiVersion + base + '/view/{id}',
      method: 'GET'
    }
  }
}

export default infoApi
