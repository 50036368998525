import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/gather/spot'
const gatherSpotApi = () => {
  return {
    import: {
      path: serverUrl + apiVersion + base + '/import',
      method: 'POST'
    },
    select: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET'
    },
    selectAll: {
      path: serverUrl + apiVersion + base + '/selectAll',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    searchSpot: {
      path: serverUrl + apiVersion + base + '/searchSpot',
      method: 'GET'
    },
    searchSpotTotal: {
      path: serverUrl + apiVersion + base + '/searchSpotTotal',
      method: 'GET'
    },
    exportSpotTotal: {
      path: serverUrl + apiVersion + base + '/exportSpotTotal',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    updateMoreDriver: {
      path: serverUrl + apiVersion + base + '/update_driver_id',
      method: 'PUT'
    },
    updateMoreDispatcher: {
      path: serverUrl + apiVersion + base + '/update_dispatcher_id',
      method: 'PUT'
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'String'
      }
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    findOneByNo: {
      path: serverUrl + apiVersion + base + '/find_by_no',
      method: 'GET'
    },
    getUserProfile: {
      path: serverUrl + apiVersion + base + '/find_by_userName_userPhone',
      method: 'GET'
    },
    findOneByKeyLike: {
      path: serverUrl + apiVersion + base + '/find_by_key_like',
      method: 'GET'
    },
    findOneByNoLike: {
      path: serverUrl + apiVersion + base + '/find_by_no_like',
      method: 'GET'
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    gather_spot_statistic: {
      path: serverUrl + apiVersion + base + '/gather_spot_statistic',
      method: 'GET',
    },
    areaTotal: {
      path: serverUrl + apiVersion + base + '/areaTotal',
      method: 'GET'
    },
  }
}
export default gatherSpotApi