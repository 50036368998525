import serverUrl from '../service.info'

const apiVersion = '/api/v1/camera/hk/info'

const hkInfo = {
  search: {
    path: serverUrl + apiVersion + '/search',
    method: 'GET',
  },
  view: {
    path: serverUrl + apiVersion + '/{id}/view',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/create',
    method: 'POST',
  },
  update: {
    path: serverUrl + apiVersion + '/{id}/update',
    method: 'PUT',
  },
  delete: {
    path: serverUrl + apiVersion + '/{id}/del',
    method: 'DELETE',
  },
  play: {
    path: serverUrl + apiVersion + '/{id}/play',
    method: 'GET',
  },
  synchronous_device: {
    path: serverUrl + apiVersion + '/{id}/synchronous_device',
    method: 'GET',
  },
  synchronous_devices: {
    path: serverUrl + apiVersion + '/synchronous_devices',
    method: 'post',
  },
}

export default hkInfo
