import { RouteView } from '../../components/layouts'

const facility = [
  {
    path: '/equipment2/facility',
    component: RouteView,
    redirect: '/equipment2/facility',
    name: 'facilityInfo',
    meta: { title: '设施基本信息', keepAlive: false },
    children: [
      {
        path: '/equipment2/facility',
        name: 'facility',
        component: () => import('../../views/equipment2/facility/List'),
        meta: { title: '设施基本信息列表', keepAlive: false }
      }
    ]
  }
]

export default facility