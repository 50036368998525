import { RouteView } from '../components/layouts'

const dutyNoteConfig = {
  path: '/dutyNote',
  component: RouteView,
  redirect: '/dutyNote/List',
  name: 'dutyNote',
  meta: { title: '值班记事', keepAlive: false },
  children: [
    {
      path: '/dutyNote/List',
      name: 'dutyNote',
      component: () => import('../views/csgz/dutyNote/List'),
      meta: { title: '值班记事' }
    },
  ]

}
export default dutyNoteConfig