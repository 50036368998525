import { RouteView } from '../components/layouts'

const damagedreportRouter = {
  path: '/damagedreport',
  component: RouteView,
  redirect: '/damagedreport/report',
  name: 'damagedreport',
  meta: { title: '电话记录', keepAlive: false },
  children: [
    {
      path: '/damagedreport/report',
      name: 'report',
      component: () => import('../views/ticket/damagereport/List'),
      meta: { title: '登记', keepAlive: false }
    },
    {
      path: '/damagedreport/dispatch',
      name: 'dispatch',
      component: () => import('../views/ticket/damagereport/DispatchList'),
      meta: { title: '调度', keepAlive: false }
    },
    {
      path: '/damagedreport/handle',
      name: 'dispatch',
      component: () => import('../views/ticket/damagereport/HandleList'),
      meta: { title: '处理', keepAlive: false }
    },
    {
      path: '/damagedreport/feedback',
      name: 'feedback',
      component: () => import('../views/ticket/damagereport/FeedbackList'),
      meta: { title: '回访', keepAlive: false }
    },
    {
      path: '/damagedreport/confirm',
      name: 'confirm',
      component: () => import('../views/ticket/damagereport/ConfirmList'),
      meta: { title: '确认', keepAlive: false }
    },
    {
      path: '/damagedreport/statistics',
      name: 'statistics',
      component: () => import('../views/ticket/damagereport/StatisticsList'),
      meta: { title: '统计', keepAlive: false }
    },
    {
      path: '/damagedreport/loopForecast',
      name: 'loopForecast',
      component: () => import('../views/ticket/loopForecast/List'),
      meta: { title: '清运预测', keepAlive: false }
    },
  ]
}

export default damagedreportRouter