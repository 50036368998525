const common = {
  state: {
    intervalMessage: '',
    // mapStyle: 'grey'
    //mapStyle: '8ea104d16c14b242522f70fbda71927c'
    mapStyle: 'normal',
    gatherSpots: []
  },
  mutations: {
    SET_INTERVAL_MESSAGE: (state, intervalMessage) => {
      state.intervalMessage = intervalMessage
    },
    SET_MAP_STYLE: (state, mapStyle) => {
      state.mapStyle = mapStyle
    },
    SET_GATHER_SPOTS: (state, gatherSpots) => {
      state.gatherSpots = gatherSpots
    },
  }
}

export default common
