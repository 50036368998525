import { RouteView } from '../components/layouts'

const csgzReportRecordRouter = {
  path: '/baseinfo',
  component: RouteView,
  redirect: '/baseinfo/car',
  name: 'baseinfo',
  meta: { title: '基础信息', keepAlive: false },
  children: [
    {
      path: '/baseinfo/car',
      name: 'list',
      component: () => import('../views/csgz/gatherspot/List'),
      meta: { title: '车辆管理', keepAlive: false }
    },
    {
      path: '/baseinfo/config',
      name: 'list',
      component: () => import('../views/csgz/gatherspot/List'),
      meta: { title: '车辆配置', keepAlive: false }
    },
    {
      path: '/baseinfo/gatherspot',
      name: 'list',
      component: () => import('../views/csgz/gatherspot/List'),
      meta: { title: '集污点管理', keepAlive: false }
    },
    {
      path: '/baseinfo/gatherspotSearch',
      name: 'list',
      component: () => import('../views/csgz/gatherspot/ListSpot'),
      meta: { title: '集污点查询', keepAlive: false }
    },
    {
      path: '/baseinfo/gatherBindSpot',
      name: 'list',
      component: () => import('../views/csgz/gatherspot/spotBindList'),
      meta: { title: '集污点绑定管理', keepAlive: false }
    },
    {
      path: '/baseinfo/sewageplant',
      name: 'list',
      component: () => import('../views/csgz/sewageplant/List'),
      meta: { title: '排污厂管理', keepAlive: false }
    },
    {
      path: '/baseinfo/area',
      name: 'list',
      component: () => import('../views/csgz/area/Area'),
      meta: { title: '行政区划', keepAlive: false }
    },
  ]
}

export default csgzReportRecordRouter