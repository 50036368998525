const dict = {
  state: {
    subjects: []
  },

  mutations: {
    SET_SUBJECTS: (state, subjects) => {
      state.subjects = subjects
    }
  }
}

export default dict
