import serverUrl from './service.info'

const apiVersion = '/api/v1'

const auth = {
  login: {
    path: serverUrl + apiVersion + '/login',
    method: 'POST',
  },
  loginInfo: {
    path: serverUrl + apiVersion + '/login/info',
    method: 'GET',
  },
  register: {
    path: serverUrl + apiVersion + '/register',
    method: 'POST',
  },
  activeUser: {
    path: serverUrl + apiVersion + '/activation/email/{username}/{uuid}',
    method: 'PUT',
  },
  reSendEmail: {
    path: serverUrl + apiVersion + '/send/activation/email/{username}',
    method: 'GET',
  },
  checkUser: {
    path: serverUrl + apiVersion + '/check/{type}/{str}',
    method: 'GET',
  },
  checkUserAndUserId: {
    path: serverUrl + apiVersion + '/check/{userId}/{type}/{str}',
    method: 'GET',
  },
  currentUser: {
    path: serverUrl + apiVersion + '/login/current/user',
    method: 'GET',
  },
  get: {
    path: serverUrl + apiVersion + '/auth/get',
    method: 'GET',
  },
  loginOut: {
    path: serverUrl + apiVersion + '/login/out',
    method: 'GET',
  },
  search: {
    path: serverUrl + apiVersion + '/auth/search',
    method: 'GET',
  },
  update: {
    path: serverUrl + apiVersion + '/auth/{id}/update/{rId}',
    method: 'PUT',
  },
  overrideUpdate: {
    path: serverUrl + apiVersion + '/auth/{id}/override/update',
    method: 'PUT',
  },
  viewRoleList: {
    path: serverUrl + apiVersion + '/auth/{id}/view',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  viewAuth: {
    path: serverUrl + apiVersion + '/auth/{id}/override/view',
    method: 'GET',
    params: {
      id: 'String',
    },
  },
  updateAuthByRoleIdList: {
    path: serverUrl + apiVersion + '/auth/{id}/update/list',
    method: 'PUT',
    params: {
      id: 'String',
    },
  },
  createInit: {
    path: serverUrl + apiVersion + '/auth/create/init',
    method: 'GET',
  },
  createInitOne: {
    path: serverUrl + apiVersion + '/auth/create/init/{opModule}/{opId}',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + '/auth/create',
    method: 'POST',
  },
  delete: {
    path: serverUrl + apiVersion + '/auth/{id}/del',
    method: 'DELETE',
  },
  deleteMore: {
    path: serverUrl + apiVersion + '/auth/delMore',
    method: 'DELETE',
  },
  checkPassword: {
    path: serverUrl + apiVersion + '/checkPassword',
    method: 'POST',
  },
  password: {
    path: serverUrl + apiVersion + '/password',
    method: 'PUT',
  },
  invitation: {
    path: serverUrl + apiVersion + '/invitation',
    method: 'POST',
  },
  removeUser: {
    path: serverUrl + apiVersion + '/remove/{uid}/team',
    method: 'POST',
    params: {
      uid: 'Long',
    },
  },
  createMore: {
    path: serverUrl + apiVersion + '/auth/create/more/{opModule}/{opId}',
    method: 'POST',
  },
  editPassword: {
    path: serverUrl + apiVersion + '/password',
    method: 'PUT',
  },
  system: {
    search: {
      path: serverUrl + apiVersion + '/config/search',
      method: 'GET',
    },
    update: {
      path: serverUrl + apiVersion + '/config/{id}/update',
      method: 'PUT',
    },
    hibernateCache: {
      path: serverUrl + apiVersion + '/cache/level2/evict',
      method: 'POST',
    },
    shiroCache: {
      path: serverUrl + apiVersion + '/cache/shiro/auth/evict',
      method: 'POST',
    },
    info: {
      path: serverUrl + apiVersion + '/system/info',
      method: 'GET',
    },
    logs: {
      path: serverUrl + apiVersion + '/logs/search',
      method: 'GET',
    },
    logsClear: {
      path: serverUrl + apiVersion + '/logs/clear',
      method: 'POST',
    },
    logDetail: {
      path: serverUrl + apiVersion + '/logs/{id}/view',
      method: 'GET',
    },
  },
}

export default auth
