import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/report/record'
const damageReportApi = () => {
  return {
    panelReportRecord: {
      path: serverUrl + apiVersion + base + '/panel_report_record',
      method: 'GET'
    },
    panelDriverTasks: {
      path: serverUrl + apiVersion + base + '/panel_driver_tasks',
      method: 'GET'
    },
    panelUnCompleteReports: {
      path: serverUrl + apiVersion + base + '/panel_uncomplete_reports',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    list: {
      path: serverUrl + apiVersion + base + '/list',
      method: 'GET'
    },
    mapList: {
      path: serverUrl + apiVersion + base + '/map_list',
      method: 'GET'
    },
    statistics: {
      path: serverUrl + apiVersion + base + '/statistics',
      method: 'GET'
    },
    screen_damage_ranking: {
      path: serverUrl + apiVersion + base + '/screen_damage_ranking',
      method: 'GET'
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    updateIfUrgent: {
      path: serverUrl + apiVersion + base + '/update_if_urgent',
      method: 'POST'
    },
    updateDispatch: {
      path: serverUrl + apiVersion + base + '/update_dispatch',
      method: 'POST'
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'String'
      }
    },
    updateHandleStatus: {
      path: serverUrl + apiVersion + base + '/{id}/update_handleStatus',
      method: 'PUT',
      params: {
        id: 'String'
      }
    },
    updateMultipleHandleStatus: {
      path: serverUrl + apiVersion + base + '/update_multiple_handleStatus',
      method: 'PUT',
      params: {
        id: 'String'
      }
    },
    updateReport: {
      path: serverUrl + apiVersion + base + '/{id}/updateReport',
      method: 'PUT',
      params: {
        id: 'String'
      }
    },
    register: {
      path: serverUrl + apiVersion + base + '/register',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    viewAll: {
      path: serverUrl + apiVersion + base + '/{id}/view_all',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    report_statistics: {
      path: serverUrl + apiVersion + base + '/report_statistics',
      method: 'GET'
    },
    export: {
      path: serverUrl + apiVersion + base + '/export',
      method: 'GET'
    },
    export_word: {
      path: serverUrl + apiVersion + base + '/export_word',
      method: 'GET'
    },
    export_spot_statistics_info: {
      path: serverUrl + apiVersion + base + '/export_spot_statistics_info',
      method: 'GET'
    },
    print_report_record: {
      path: serverUrl + apiVersion + base + '/print_report_record/{spotId}/{reportDate}',
      method: 'GET',
      params: {
        spotId: 'Long',
        reportDate: 'String'
      }
    }
  }
}
export default damageReportApi