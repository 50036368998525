import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/xunjian/record'
const recordApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    findRecordNodes: {
      path: serverUrl + apiVersion + base + '/find_record_nodes',
      method: 'GET'
    },
    findRecordDetail: {
      path: serverUrl + apiVersion + base + '/find_record_detail',
      method: 'GET'
    },
    count_times: {
      path: serverUrl + apiVersion + base + '/count_times',
      method: 'GET'
    },
    find_detail_for_map: {
      path: serverUrl + apiVersion + base + '/find_detail_for_map/{userId}',
      method: 'GET',
      params: {
        userId: 'Long'
      }
    },
    statistic_general: {
      path: serverUrl + apiVersion + base + '/statistic_general',
      method: 'GET'
    },
    statistic_record_times: {
      path: serverUrl + apiVersion + base + '/statistic_record_times',
      method: 'GET'
    }
  }
}
export default recordApi