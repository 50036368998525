import { RouteView } from '../components/layouts'

const xjRouter = {
  path: '/xj',
  component: RouteView,
  redirect: '/xj/list',
  name: 'inspection',
  meta: { title: '巡检', keepAlive: false },
  children: [
    {
      path: '/xj/vindicate',
      name: 'vindicate',
      component: () => import('../views/xj/vindicate/List'),
      meta: { title: '巡检项维护', keepAlive: false }
    },
    {
      path: '/xj/myInspection',
      name: 'myInspection',
      component: () => import('../views/xj/myInspection/List'),
      meta: { title: '我的巡检', keepAlive: false }
    },
    {
      path: '/xj/inquire',
      name: 'inquire',
      component: () => import('../views/xj/inquire/List'),
      meta: { title: '巡检查询', keepAlive: false }
    },
    {
      path: '/xj/myTrouble',
      name: 'myTrouble',
      component: () => import('../views/xj/myTrouble/List'),
      meta: { title: '我的故障', keepAlive: false }
    },
    {
      path: '/xj/troubleInquire',
      name: 'troubleInquire',
      component: () => import('../views/xj/troubleInquire/List'),
      meta: { title: '故障查询', keepAlive: false }
    },
  ]
}

export default xjRouter