import { RouteView } from '../../components/layouts'
const inspectionCheck = [
  {
    path: '/equipment2/equipmentInfo',
    component: RouteView,
    redirect: '/equipment2/equipmentInfo',
    name: 'info',
    meta: { title: '设备管理', keepAlive: false },
    children: [
      {
        path: '/equipment2/equipmentInfo',
        name: 'infoHome',
        component: () => import('../../views/equipment2/equipmentInfo/List'),
        meta: { title: '在线设备基本信息', keepAlive: false },
      },
      {
        path: '/equipment2/inventoryInfo',
        name: 'inventory',
        component: () => import('../../views/equipment2/equipmentInfo/InventoryList'),
        meta: { title: '库存设备基本信息', keepAlive: false },
      },
      {
        path: '/equipment2/metricsConfig',
        name: 'metricsRealInfo',
        component: () => import('../../views/equipment2/metricsConfig/List'),
        meta: { title: '指标管理', keepAlive: false },
      },
      {
        path: '/equipment2/gateway',
        name: 'gatewayHome',
        component: () => import('../../views/equipment2/gateway/List'),
        meta: { title: '网关', keepAlive: false },
      },
      {
        path: '/equipment2/monitoringPoint',
        name: 'monitoringPoint',
        component: () => import('../../views/equipment2/monitoringPoint/List'),
        meta: { title: '监测点', keepAlive: false },
      },
      {
        path: '/equipment2/monitoringPointConfig',
        name: 'monitoringPointConfig',
        component: () => import('../../views/equipment2/monitoringPointConfig/List'),
        meta: { title: '监测点配置', keepAlive: false },
      },
    ],
  },
]

export default inspectionCheck
