import { RouteView } from '../../components/layouts'

const record = [
  {
    path: '/car/record',
    component: RouteView,
    redirect: '/car/record',
    name: 'carRecord',
    meta: { title: '车辆管理', keepAlive: false },
    children: [
      {
        path: '/car/record/myGiven',
        name: 'recordGiven',
        component: () => import('../../views/car/record/mygiven/givenList'),
        meta: { title: '我下达的任务', keepAlive: false }
      },
      {
        path: '/car/record/myreceive',
        name: 'recordReceive',
        component: () => import('../../views/car/record/myreceive/receiveList'),
        meta: { title: '我执行的任务', keepAlive: false }
      },
      {
        path: '/car/record',
        name: 'record',
        component: () => import('../../views/car/record/List'),
        meta: { title: '任务查询', keepAlive: false }
      },
      {
        path: '/car/config',
        name: 'config',
        component: () => import('../../views/car/config/List'),
        meta: { title: '司机车辆配置', keepAlive: false }
      },
      {
        path: '/car/maintain',
        name: 'config',
        component: () => import('../../views/car/maintain/List'),
        meta: { title: '车辆维护', keepAlive: false }
      },
      {
        path: '/car/loop/record',
        name: 'loop',
        component: () => import('../../views/car/looprecord/List'),
        meta: { title: '清运记录', keepAlive: false }
      },
      {
        path: '/car/xjrecord',
        name: 'record',
        component: () => import('../../views/car/xjrecord/List'),
        meta: { title: '巡检车辆查询', keepAlive: false }
      }
    ]
  }
]

export default record