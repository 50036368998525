import serverUrl from './service.info'

const apiVersion = '/api/v1/system'
const base = '/extend'
const extendInfo = {
  search: {
    path: serverUrl + apiVersion + base + '/search',
    method: 'GET',
  },
  create: {
    path: serverUrl + apiVersion + base + '/create',
    method: 'POST',
  },
  view: {
    path: serverUrl + apiVersion + base + '/{id}/view',
    method: 'GET',
    params: {
      id: 'Long',
    },
  },
  update: {
    path: serverUrl + apiVersion + base + '/{id}/update',
    method: 'PUT',
    params: {
      id: 'Long',
    },
  },
  delete: {
    path: serverUrl + apiVersion + base + '/{id}/del',
    method: 'DELETE',
  },
  update_sort: {
    path: serverUrl + apiVersion + base + '/update_sort',
    method: 'PUT',
  },
}

export default extendInfo
