import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/csgz/area/dictionary'

const areaDictionaryApi = () => {
  return {
    tree: {
      path: serverUrl + apiVersion + base + '/tree',
      method: 'GET'
    },
    cascade: {
      path: serverUrl + apiVersion + base + '/cascade',
      method: 'GET'
    },
    select: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    selectNoId: {
      path: serverUrl + apiVersion + base + '/select',
      method: 'GET',
    },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    }
  }
}
export default areaDictionaryApi