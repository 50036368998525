import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/monitorPoint'
const monitorPointApi = () => {
  return {
    search: {
      path: serverUrl + apiVersion + base + '/search',
      method: 'GET'
    },
    searchTypeList: {
      path: serverUrl + apiVersion + base + '/searchTypeList',
      method: 'GET'
    },
    searchTypeList2: {
      path: serverUrl + apiVersion + base + '/search_type_list2/{mapInfoId}',
      method: 'GET'
    },
    info: {
      path: serverUrl + apiVersion + base + '/info',
      method: 'GET'
    },
    searchByLayer: {
      path: serverUrl + apiVersion + base + '/search_by_layer',
      method: 'POST'
    },
    layerSelect: {
      path: serverUrl + apiVersion + base + '/layer_select/{mapInfoId}',
      method: 'GET'
    },
    customSearchByBiz: {
      path: serverUrl + apiVersion + base + '/search_by_biz',
      method: 'POST'
    },
    layerSelectType: {
      path: serverUrl + apiVersion + base + '/layer_select_type/{mapId}',
      method: 'GET'
    },
    layerSelectType2: {
      path: serverUrl + apiVersion + base + '/layer_select_v2/{mapInfoId}',
      method: 'GET'
    }
  }
}
export default monitorPointApi