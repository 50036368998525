import { RouteView } from '../components/layouts'

const invokeStatistic = {
  path: '/invoke',
  component: RouteView,
  redirect: '/invoke/list',
  name: 'invoke',
  meta: { title: '调用统计', keepAlive: false },
  children: [
    {
      path: '/invoke/list',
      name: 'InvokeList',
      component: () => import('../views/invoke/List'),
      meta: { title: '调用统计' }
    },
  ]

}
export default invokeStatistic