<template>
  <a-popover
    v-model="visible"
    trigger="click"
    @visibleChange="handleHoverChange"
    placement="bottomRight"
    :autoAdjustOverflow="true"
    :arrowPointAtCenter="true"
    overlayClassName="header-notice-wrapper"
    :overlayStyle="{ width: '300px', top: '50px' }">
    <!--<template slot="content">-->
    <!--<a-spin :spinning="loading">-->
    <!--<a-tabs @change="onTabChange">-->
    <!--<a-tab-pane tab="通知" key="notice">-->
    <!--<a-list :dataSource="noticeData" :pagination="pagination">-->
    <!--<a-list-item slot="renderItem" slot-scope="item">-->
    <!--<a-list-item-meta>-->
    <!--<a-avatar slot="avatar" style="background-color: rgba(255,255,255,0.2);">-->
    <!--<a-icon type="sound" theme="filled" class="icon" style="color: #1890ff;"/>-->
    <!--</a-avatar>-->
    <!--<div slot="title">-->
    <!--<span>{{ item.publisher }}</span>&nbsp;-->
    <!--发布了&nbsp;<a href="#" @click="noticeClick(item.id)">{{ item.title }}</a>&nbsp;-->
    <!--<span> &nbsp;的通知</span>&nbsp;-->
    <!--<span style="display: block;float: right;">{{item.dateCreated|transformTime}}前</span>-->
    <!--&lt;!&ndash;<div slot="description">&ndash;&gt;-->
    <!--&lt;!&ndash;{{item.dateCreated|transformTime}}前&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--</div>-->
    <!--</a-list-item-meta>-->
    <!--</a-list-item>-->
    <!--</a-list>-->
    <!--</a-tab-pane>-->
    <!--<a-tab-pane tab="消息" key="news">-->
    <!--<a-list :dataSource="newsData" :pagination="pagination"-->
    <!--&gt;-->
    <!--<a-list-item slot="renderItem" slot-scope="item">-->
    <!--<a-list-item-meta>-->
    <!--<a-avatar slot="avatar" style="background-color: rgba(255,255,255,0.2);">-->
    <!--<a-icon type="message" theme="filled" class="icon" style="color: #1890ff;"/>-->
    <!--</a-avatar>-->
    <!--<div slot="title">-->
    <!--<span>{{ item.supervise.publisher }}</span>&nbsp;-->
    <!--<span>向您发起了&nbsp;</span>-->
    <!--<span>{{ item.supervise.title }}。</span>&nbsp;-->
    <!--</div>-->
    <!--<div slot="description">{{item.supervise.dateCreated|transformTime}}前</div>-->
    <!--</a-list-item-meta>-->
    <!--</a-list-item>-->
    <!--</a-list>-->
    <!--</a-tab-pane>-->
    <!--<a-tab-pane tab="待办" key="supervise">-->
    <!--<a-list :dataSource="superviseData" :pagination="pagination"-->
    <!--&gt;-->
    <!--<a-list-item slot="renderItem" slot-scope="item">-->
    <!--<a-list-item-meta>-->
    <!--<a-avatar slot="avatar">{{item.supervise.publisher}}</a-avatar>-->
    <!--<div slot="title">-->
    <!--<span>{{ item.supervise.publisher }}</span>&nbsp;-->
    <!--<span>向您发起了&nbsp;</span>-->
    <!--<span>{{ item.supervise.title }}。</span>&nbsp;-->
    <!--</div>-->
    <!--<div slot="description">{{item.supervise.dateCreated|transformTime}}前</div>-->
    <!--</a-list-item-meta>-->
    <!--&lt;!&ndash;<div>&ndash;&gt;-->
    <!--&lt;!&ndash;<a href="">详情</a>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->
    <!--</a-list-item>-->
    <!--</a-list>-->
    <!--</a-tab-pane>-->
    <!--</a-tabs>-->
    <!--</a-spin>-->
    <!--</template>-->
    <span @click="fetchNotice" class="header-notice">
      <a-badge>
        <a-icon style="font-size: 16px; padding: 4px" type="bell"/>
      </a-badge>
    </span>
  </a-popover>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'
  import moment from 'moment'

  export default {
    name: 'HeaderNotice',
    data () {
      return {
        loading: true,
        visible: false,
        searchParams: {
          type: '',
          userId: '',
          number: '1',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        },
        searchNotice: {
          read: '',
          receiveId: '',
          number: '1',
          size: '10',
          receiveType: 'all',
          direction: 'DESC',
          order: 'dateCreated'
        },
        searchNews: {
          read: '',
          receiveId: '',
          number: '1',
          size: '10',
          receiveType: 'all',
          direction: 'DESC',
          order: 'dateCreated'
        },
        tabList: [
          {
            key: 'notice',
            tab: '通知'
          },
          {
            key: 'news',
            tab: '消息'
          },
          {
            key: 'supervise',
            tab: '待办'
          }],
        /*分页*/
        pagination: {
          onChange: (page) => {
            this.loading = true
            this.searchParams.number = page
            if (this.type === 'supervise') {
              this.loadSuperViseData()
            } else if (this.type === 'notice') {
              this.loadNoticeData()
            } else if (this.type === 'news') {
              this.loadNewsData()
            }
          },
          total: 0,
          simple: true,
          pageSize: 10,
          showSizeChanger: true
        },
        appModuleType: [
          { value: 'apply', name: '装备申请' },
          { value: 'storage', name: '装备入库' },
          { value: 'allot', name: '装备划拨' },
          { value: 'receive', name: '物资领用' },
          { value: 'borrow', name: '物资借用' },
          { value: 'repair', name: '维修保养' },
          { value: 'scrap', name: '报损报废' },
          { value: 'inventory', name: '装备盘点' },
          { value: 'notice', name: '实验通知单' },
          { value: 'template', name: '实验项目' },
          { value: 'labForm', name: '创建实验项目' },
          { value: 'program', name: '教学计划' }
        ],
        key: 'notice',
        tabKey: 'notice',
        superviseData: [],
        noticeData: [],
        newsData: [],
        url: '',
        type: '',
        avatar: ''
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    filters: {
      transformTime (time) {
        return moment(time).fromNow(true)
      }
    },
    created () {
      this.searchParams.userId = this.currentUser.id
      this.searchNotice.instancId = this.currentUser.id
      this.avatar = this.currentUser.avatar
      this.loadNoticeData()
    },
    methods: {
      fetchNotice () {

      },
      handleHoverChange (visible) {
        this.visible = false
        this.$router.push('/user/workplace')
      },
      loadSuperViseData () {
        this.loading = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.superviseState.search,
          data: this.searchParams,
          noTips: 'true',
          success: (data) => {
            this.loading = false
            this.superviseData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[2].tab = '待办' + '(' + data.body.totalElements + ')'
          }
        })
      },
      noticeClick (id) {
        this.$router.push(
          {
            path: '/system/notice/detail',
            query: {
              id: id
            }
          }
        )
      },
      loadNewsData () {
        this.loading = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.superviseState.search,
          data: this.searchParams,
          noTips: 'true',
          success: (data) => {
            this.loading = false
            this.newsData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[1].tab = '消息' + '(' + data.body.totalElements + ')'
          }
        })
      },
      loadNoticeData () {
        this.loading = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.list,
          data: this.searchNotice,
          noTips: 'true',
          success: (data) => {
            this.loading = false
            this.noticeData = data.body.content
            this.pagination.total = data.body.totalElements
            this.tabList[0].tab = '通知' + '(' + data.body.totalElements + ')'
          }
        })
      },
      onTabChange (key) {
        console.log('11111')
        if (key === 'notice') {
          this.type = key
          this.loadNoticeData()
        } else if (key === 'supervise') {
          this.type = key
          this.loadSuperViseData()
        } else if (key === 'news') {
          this.type = key
          this.loadNewsData()
        }
      }
    }
  }
</script>

<style lang="css">
  .header-notice-wrapper {
    top: 50px !important;
  }
</style>
<style lang="less" scoped>
  .header-notice {
    display: inline-block;
    transition: all 0.3s;

    span {
      vertical-align: initial;
    }
  }
</style>
