import { RouteView } from '../components/layouts'

const csgzPanelRouter = {
  path: '/dispatch/panel',
  component: RouteView,
  redirect: '/dispatch/panel',
  name: 'dispatchPanel',
  meta: { title: '调度面板', keepAlive: false },
  children: [
    {
      path: '/dispatch/panel/xq',
      name: 'panel',
      component: () => import('../views/csgz/dispatchpanel/cover/Cover'),
      meta: { title: '调度面板', keepAlive: false, hideMargin: true, hideFooter: true }
    },
    {
      path: '/dispatch/panel/zn',
      name: 'panel',
      component: () => import('../views/csgz/dispatchpanel/cover/CoverZn'),
      meta: { title: '调度面板', keepAlive: false, hideMargin: true, hideFooter: true }
    }
  ]
}

export default csgzPanelRouter