import serverUrl from '@/api/service.info'

const apiVersion = '/api/v1'
const base = '/device/metrics/real'
const metricsRealInfoApi = () => {
  return {
    // search: {
    //   path: serverUrl + apiVersion + base + '/find_list_by_point_id',
    //   method: 'GET'
    // },
    create: {
      path: serverUrl + apiVersion + base + '/create',
      method: 'POST'
    },
    view: {
      path: serverUrl + apiVersion + base + '/{id}/view',
      method: 'GET',
      params: {
        id: 'Long'
      }
    },
    delete: {
      path: serverUrl + apiVersion + base + '/{id}/del',
      method: 'DELETE',
      params: {
        id: 'Long'
      }
    },
    update: {
      path: serverUrl + apiVersion + base + '/{id}/update',
      method: 'PUT',
      params: {
        id: 'Long'
      }
    },
    selectGateWay: {
      path: serverUrl + apiVersion + base + '/selectGateWay',
      method: 'GET'
    },
    search: {
      path: serverUrl + apiVersion + base + '/find_list_by_point_id',
      method: 'GET'
    },
    find_list_group_by_tag: {
      path: serverUrl + apiVersion + base + '/find_list_group_by_tag',
      method: 'GET'
    },
    screen_out_water_monitor: {
      path: serverUrl + apiVersion + base + '/screen_out_water_monitor',
      method: 'GET'
    }
  }
}
export default metricsRealInfoApi
